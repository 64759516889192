<template>
  
</template>

<script>
export default {
  mounted() { 
    this.$swal.fire({
      title: `Are you sure to logout?`,
      icon: `question`,
      allowOutsideClick: false,
      heightAuto:false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, I'm sure`
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.$store.dispatch("user/logout").then(() => {
          localStorage.removeItem('vuex');
          this.$router.push(`/login`);
        });
      }
    })
  }
}
</script>

<style>

</style>